import Image from 'next/image';

const SelectLanguageTrigger = ({ darkMode, currentLocale }: { darkMode?: boolean; currentLocale: string }) => {
  return (
    <div className="flex cursor-pointer flex-row items-center justify-start">
      <Image
        width={48}
        height={48}
        src={`/images/navBar/${darkMode ? 'earth_white' : 'earth'}.png`}
        className="h-auto w-[24px]"
        alt="earthImage"
      />
      <div className={`ml-2 text-sm font-medium ${darkMode ? 'text-white' : 'text-black'}`}>
        {currentLocale === 'en' ? 'Eng' : currentLocale === 'ru' ? 'Рус' : 'Қаз'}
      </div>
    </div>
  );
};

export default SelectLanguageTrigger;
